import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import AndroidServiceBanner from '../images/android_service_banner.svg'
import AndroidServiceFeature from '../images/android_service_features.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'

import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const AndroidAppDevelopment = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/android-app-development-services" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Android App Development Services" description='eWise - Android App Development Services'/>
 <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90 block">Android App</strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-10 2xl:pl-14 text-gray text-2xl md:text-3xl lg:text-34 xl:text-34 2xl:text-50">Development Services</span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4 sm:pt-0">
                        <div className="inner relative xl:p-12 2xl:p-0">
                            <img className="w-full sm:w-auto max-w-350 md:max-w-420 lg:max-w-470 m-auto lazyload" loading="lazy" data-src={AndroidServiceBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
    <section className="relative">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content pb-10 md:pb-12 lg:pb-16 xl:pb-24 px-4 xl:pr-16 2xl:pr-32">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-35 2xl:text-40 ">Power your business mobile applications with Android - change the way you do things!<span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p>As businesses grow, the processes get complicated and android is one such mobile application development platform that can help you streamline your workflows. Get the power to amplify your user experience through an android mobile application. Android offers a Native app experience that enables you to interact with and take advantage of operating system features and other software that is installed on the platform. The simple and intuitive platform helps you to create beautiful and engaging business mobile platforms.</p>
            </div>

          </div>
      </div>
  </section>
  <section className="relative pb-8 sm:pb-10 lg:pb-14">
      <div className="container">
          <div className="-mx-4">
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full lg:w-1/2 px-4 order-2 lg:order-1">
                
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Here are plethora of features you’ll <br className="hidden xl:inline-block"/> love<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Choose the target platform that is best for your business</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Manage every kind of device, even employee-owned</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Build robust and secure mobile applications and avoid any data leak</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get google updates to ensure the security at the top most level</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Offer business space and personal space on the same device for your associates</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Enrol all your organization’s devices at a time</li>
                        <li className="pl-10 relative">Transfer your data wirelessly from one device to other</li>
                    </ul>
                </div>
                <div className="features_right w-full lg:w-1/2 flex justify-center px-4 order-1 lg:order-2">
                    <img className="object-contain max-w-330 md:max-w-363  lg:max-w-420 2xl:max-w-420 mb-5 lg:mb-0 w-full lazyload" loading="lazy" data-src={AndroidServiceFeature} alt="iphone"/>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full px-4 order-2 md:order-1">
                
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise developed Android mobile apps makes management easier!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Deploy the devices of your choice. Let your associates use their own devices through two profiles on the same device. When they turn off the work mode, office apps notifications stop!</li>
                        <li className="pl-10 relative">Configure hundreds of devices at a time. The zero-touch enrolment enables you to ready the multiple platform devices with the same configuration at one go. You can also do it with a simple QR code that is too secure!</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap  pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full px-4 order-2 md:order-1">
                   
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise assures security with its Android applications!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Before a device gets configured with the android application, it checks whether the environment is safe. The anti-exploitation keeps the check on vulnerabilities. Critical security functions happen in the TEE separate from the OS.</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">The Google services ensure that business data is always separated from personal. You can download apps without downloading the threats with it, thus protecting the hardware and mitigating the risk!</li>
                        <li className="pl-10 relative">The compliance policies can be configured on all the devices, which means you are in control of every device that is deployed. The security also keeps personal and office data leak-proof!</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap  pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full px-4 order-2 md:order-1">
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise can build you Android Management Sets<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="pb-4">Provide the flexibility and agility your workforce needs with Android Enterprise management systems. We are an approved EMM provider and verified by Google to support all the standard features for each management option they offer.</p>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Work Profile Management BYOD and personally-enabled devices</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Dedicated Device Management Corporate-owned, single-use devices</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Full Device Management Corporate-owned, business only devices</li>
                        <li className="pl-10 relative">Mobile App Management (MAM) Application management</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap  pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise can offer mobile application development services for multiple industries and <br/> devices!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Retail</h5>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get the apps for scanners to track inventory</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">AR and VR mobile applications to engage your customers</li>
                        <li className="pl-10 relative">Applications for devices that help make restocking easier, floor sales smoother</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Financial services</h5>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Keep work and customer data protected through strict security policies</li>
                        <li className="pl-10 relative">Android devices and applications help keep the data integrity and security</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Transportation and logistics</h5>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Be assured of the products and services to delivered on time, everytime</li>
                        <li className="pl-10 relative">Android devices and applications enable your associates to manage inventory and track assets</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Hospitality</h5>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Let your customers experience the optimum level of hospitality</li>
                        <li className="pl-10 relative">Enable yourself to run kiosks for food ordering, info or payment</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Manufacturing</h5>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Reduce your costs, and maintain higher quality with max efficiency</li>
                        <li className="pl-10 relative">Select from many Android devices that track real-time data</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-0 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Healthcare</h5>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Efficiently monitor patients remotely</li>
                        <li className="pl-10 relative">Get patients’ files and records from the cloud, or do research</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap  pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why hire eWise as an Android expert!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="px-4 pb-4">Androidis a stunning mobile application platform that helps every business to build their flexible network the way they want. The intuitive and easy to manage applications together make it viable for various industry needs. Choosing Android as your mobile application platform? eWise will enable you to build one!</p>
                    <p className="px-4 pb-4">eWise has an extremely professional and efficient team that is well versed with latest technologies and their every feature and technique. We ensure that your mobile application development using Android is completely efficient and productive for your business . We strive to equip your mobile with functionalities, which would help your users to efficiently use them.</p>
                    <p className="px-4">We promise to deliver the best mobile application project in both your timeline and budget!</p>
                </div>
            </div>
          </div>
      </div>
      <img className="absolute right-0 bottom-1/4 z-1 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="Service Circle"/>
  </section>

    </Layout>
  )
}

export default AndroidAppDevelopment